const tabs = document.querySelectorAll('.tabs');

if (tabs) {

	tabs.forEach( (item) => {
		let tabInfos = item.querySelectorAll('.tabInfo');
		let tabLinks = item.querySelectorAll('.tabsLink');

		tabLinks.forEach( (item) => {

			item.addEventListener('click', () => {
				tabInfos.forEach( (item) => {
					item.classList.add('hidden');
					item.setAttribute('aria-hidden', 'true');
				})

				tabLinks.forEach( (item) => {
					item.classList.remove('is-active');
					item.setAttribute('aria-selected', 'false');
				})

				item.classList.toggle('is-active');
				item.setAttribute('aria-selected', 'true');

				document.getElementById(item.dataset.tab).classList.remove('hidden');
				document.getElementById(item.dataset.tab).setAttribute('aria-hidden', 'false');
				document.getElementById(item.dataset.tab).querySelector('h1.banner__title').setAttribute('tabindex', '-1');
				document.getElementById(item.dataset.tab).querySelector('h1.banner__title').focus();
			})
		})
	})
}
