const timelineBlocks = document.querySelectorAll('.timelineBlock');

if (timelineBlocks) {

    window.addEventListener('load', () => {

        timelineBlocks.forEach( (item) => {
            if (item.offsetHeight > 450) {
                item.classList.add('read-more');
            }

            item.querySelector('.timelineToggle').addEventListener('click', () => {
                item.classList.add('is-active');
                item.classList.remove('read-more');
            })
        })
    })
}