const navTrigger = document.querySelector('.navTrigger');
const nav = document.querySelector('.nav');
const socialNav = document.querySelector('.socialNav');
const secondaryNavTriggers = document.querySelectorAll('.secondaryNavTrigger')

if (navTrigger) {

    navTrigger.addEventListener('click', () => {
        // change aria-expanded on click
        let ariaExpanded = navTrigger.getAttribute('aria-expanded');

        if (ariaExpanded === "true") {
            ariaExpanded = "false"
        } else {
            ariaExpanded = "true"
        }

        navTrigger.setAttribute('aria-expanded', ariaExpanded);

        navTrigger.classList.toggle('is-active');
        nav.classList.toggle('is-active');
        socialNav.classList.toggle('hidden');
    })

    secondaryNavTriggers.forEach( (item) => {
        item.addEventListener('click', () => {
            // change aria-expanded on click
            let ariaExpanded = item.getAttribute('aria-expanded');

            if (ariaExpanded === "true") {
                ariaExpanded = "false"
            } else {
                ariaExpanded = "true"
            }

            let secondaryNav = item.parentElement.querySelector('.secondaryNav');

            item.classList.toggle('is-active');
            item.setAttribute('aria-expanded', ariaExpanded);
            secondaryNav.classList.toggle('is-active');
        })
    })
}